<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <div class="brand-logo">
        <Logo />
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="skin === 'dark'
              ? require('@/views/auth/signIn/assets/illustration-dark.svg')
              : require('@/views/auth/signIn/assets/illustration.svg')"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('auth.signIn.title', { projectName }) }}
          </b-card-title>
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              :label="$t('auth.field.email')"
              label-for="login-email"
            >
              <b-form-input
                id="login-email"
                v-model="model.email"
                :state="errors.email ? false : null"
                name="login-email"
                placeholder="ivanivanov@yandex.ru"
              />
              <small
                v-if="errors.email"
                class="text-danger"
              >
                {{ errors.email }}
              </small>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label>{{ $t('auth.field.password') }}</label>
                <b-link :to="{ name:'forgot-password' }">
                  <small>{{ $t('auth.field.forgotPassword') }}</small>
                </b-link>
              </div>
              <InputPassword
                v-model="model.password"
                :error="errors.password"
              />
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="remember"
                name="checkbox-1"
              >
                {{ $t('auth.field.remember') }}
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              @click="onSubmit"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              <template v-else>
                {{ $t('auth.signIn.submit') }}
              </template>
            </b-button>
          </b-form>

          <b-card-text class="text-center mt-2">
            <i18n
              tag="span"
              class="text-center mt-2"
              path="auth.signIn.signUpText"
            >
              <template #signUpLink>
                <b-link :to="{ name:'sign-up' }">
                  <span>{{ $t('auth.signIn.signUpLink') }}</span>
                </b-link>
              </template>
            </i18n>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
} from 'bootstrap-vue';
import { computed, ref, inject } from '@vue/composition-api';
import { required, email } from '@validations';

import Logo from '@core/layouts/components/Logo.vue';
import { getToken } from "firebase/messaging";
import { useStore } from '@/hooks/useStore';
import { useI18n } from '@/hooks/useI18n';
import { useToast } from '@/hooks/useToast';
import InputPassword from '@/components/inputPassword/InputPassword.vue';
import { useValidation } from '@/hooks/useValidation';
import {
  emailValidator,
  fieldRequiredValidator,
  maxLengthValidator,
} from '@/utils/validation';
import browserInfo from "@/hooks/browserInfo";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    Logo,
    InputPassword,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { welcomeToast, dangerToast } = useToast();

    const projectName = computed(() => store.getters['app/projectName']);
    const skin = computed(() => store.getters['appConfig/skin']);

    const model = ref({
      password: '',
      email: '',
        deviceUid: null,
        deviceName: null,
    });
    const remember = ref('');

    const PASSWORD_MAX_LENGTH = 100;

    const [validate, errors] = useValidation([
      fieldRequiredValidator(model.value, 'email', t('errors.message.emailRequired')),
      emailValidator(model.value, 'email', t('errors.message.emailInvalid')),
      fieldRequiredValidator(model.value, 'password', t('errors.message.passwordRequired')),
      maxLengthValidator(
        model.value,
        'password',
        PASSWORD_MAX_LENGTH,
        t('errors.message.passwordMaxLength', { length: PASSWORD_MAX_LENGTH }),
      ),
    ]);

      const firebase = inject('firebase');
    if (window.Notification) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          getToken(firebase.messaging, {vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY})
          .then((currentToken) => {
            if (currentToken) {
              model.value.deviceUid = currentToken;
              model.value.deviceName = `${browserInfo.browserName} ${browserInfo.majorVersion}`;
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });
        }
      });
    } else {
      console.log('Firebase Not Supported');
    }

    const isLoading = ref(false);

    const onSubmit = async () => {
      if (isLoading.value) return;

      const isFormValid = validate();
      if (!isFormValid) return;

      isLoading.value = true;
      const {
        result,
        data: userData,
      } = await store.dispatch('user/signIn', {
        ...model.value,
        remember: remember.value,
      });
      isLoading.value = false;
      if (!result) {
        dangerToast(
          t('auth.signIn.error'),
          t('auth.signIn.errors.wrongEmailOrPassword'),
        );
        return;
      }

      const { firstname } = userData;
      welcomeToast(
        t('auth.welcome.title', { firstname }),
        t('auth.welcome.text'),
      );
    };

    return {
      t,

      projectName,
      skin,

      model,
      errors,
      remember,
      isLoading,
      onSubmit,

      required,
      email,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
